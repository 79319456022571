import {
  Button,
  Col,
  Form,
  InputNumber,
  Layout,
  Row,
  Select,
  Space,
  Typography,
} from "antd";

import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { ISettingsService } from "@shared/interfaces/settings-service.interface";
import { SettingsDto } from "@shared/types/settings/settings.dto";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { SelectOptions } from "./Settings.const";
import styles from "./Settings.module.scss";

const Settings = () => {
  const [form] = Form.useForm();

  const settingsService = appInject<ISettingsService>(
    DI_TOKENS.settingsService,
  );

  const getSettingsQuery = useQuery<SettingsDto>(
    ["get-settings"],
    () => settingsService.get(),
    {
      refetchOnMount: true,
    },
  );

  const updateSettingsMutation = useMutation(
    (data: SettingsDto): Promise<SettingsDto> => settingsService.update(data),
    {
      onSuccess: () => {},
    },
  );

  useEffect(() => {
    if (getSettingsQuery.data) {
      form.setFieldsValue({
        ...getSettingsQuery.data,
        forceUpdateApp: getSettingsQuery.data.forceUpdateApp ? "1" : "0",
      });
    }
  }, [form, getSettingsQuery.data]);

  const handleFinish = (data: SettingsDto) => {
    updateSettingsMutation.mutate({
      //   draftsPerDay: +data.draftsPerDay,
      draftsOnSignup: +data.draftsOnSignup,
      usableDraftTokensPerDay: +data.usableDraftTokensPerDay,
      draftsPerReferral: +data.draftsPerReferral,
      forceUpdateApp: (data.forceUpdateApp as unknown as string) === "1",
    });
  };

  return (
    <Layout className={styles.layout}>
      <Row style={{ height: 32, justifyContent: "space-between" }}>
        <Col span={12}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Settings
          </Typography.Title>
        </Col>
      </Row>
      <Layout.Content
        className={styles.content}
        style={{ backgroundColor: "white" }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Form
            form={form}
            requiredMark={false}
            onFinish={handleFinish}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              {/* <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item
                  name="draftsPerDay"
                  label="Drafts Per Day"
                  rules={[
                    {
                      type: "number",
                      message: "This must be a number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item
                  name="draftsPerReferral"
                  label="Drafts Per Referral"
                  rules={[
                    {
                      type: "number",
                      message: "This must be a number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item
                  name="forceUpdateApp"
                  label="Force Update App"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select options={SelectOptions} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item
                  name="usableDraftTokensPerDay"
                  label="Draft Tokens Limit Per Day"
                  rules={[
                    {
                      type: "number",
                      message: "This must be a number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item
                  name="draftsOnSignup"
                  label="Drafts Tokens On Signup"
                  rules={[
                    {
                      type: "number",
                      message: "This must be a number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" style={{ marginTop: 16 }}>
              <Col>
                <Button
                  loading={updateSettingsMutation.isLoading}
                  disabled={updateSettingsMutation.isLoading}
                  size="middle"
                  type="primary"
                  htmlType="submit"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default Settings;
