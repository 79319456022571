import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";

import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IUserService } from "@shared/interfaces/user.interface";
import {
  ResetUserAccountDto,
  ResetUserDraftsDto,
  UpdateUserDto,
  UserDto,
} from "@shared/types/user/user.dto";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import ChangeUserModal from "./ChangeUserModal/ChangeUserModal";
import styles from "./Users.module.scss";
import AlertModal from "./AlertModal/AlertModal";
import { HttpErrorResponse } from "@shared/models/error/http-error-response";
import { ColumnsType } from "antd/es/table";

type IChangeUserModalType = "draftTokensRemaining" | "dailyDraftCounter";

const Users = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const [page, setPage] = useState(1);
  const [currentPageSize, setPageSize] = useState(20);

  const [containerHeight, setContainerHeight] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] =
    useState<IChangeUserModalType>("dailyDraftCounter");
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState<
    null | "reset-user" | "redeal-cards"
  >(null);

  const usersService = appInject<IUserService>(DI_TOKENS.userService);

  const columns = useMemo(
    () =>
      [
        {
          key: "id",
          dataIndex: "id",
          title: "ID",
        },
        {
          key: "nickname",
          dataIndex: "nickname",
          title: "Nickname",
        },
        {
          key: "phoneNumber",
          dataIndex: "phoneNumber",
          title: "Phone No.",
        },
        {
          key: "ranking",
          dataIndex: "ranking",
          title: "Ranking",
          className: "max-w-[90px]",
          render: (value: number | null) => (value === null ? "N/A" : value),
        },
        {
          key: "dailyDraftsCounter",
          dataIndex: "dailyDraftsCounter",
          title: "Drafts Played",
          render: (value: number) => value,
        },
        {
          key: "createdAt",
          dataIndex: "createdAt",
          title: "CreatedAt",
        },
        {
          key: "action",
          title: "Actions",
          render: (_: null, user: UserDto) => {
            return (
              <Space
                size="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography.Link
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                  onClick={() => onOpenUser(user, "dailyDraftCounter")}
                >
                  Reset Draft
                </Typography.Link>
                <Typography.Link
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                  onClick={() => {
                    setIsOpenAlertModal("redeal-cards");
                    setSelectedUser(user);
                  }}
                >
                  Re-deal Cards
                </Typography.Link>
                <Typography.Link
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                  onClick={() => {
                    setIsOpenAlertModal("reset-user");
                    setSelectedUser(user);
                  }}
                >
                  Reset User
                </Typography.Link>
                <Typography.Link
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                  onClick={() => onOpenUser(user, "draftTokensRemaining")}
                >
                  Manage Draft Tokens
                </Typography.Link>
              </Space>
            );
          },
        },
      ] as ColumnsType<UserDto>,
    [],
  );

  const usersQuery = useQuery(
    ["download-user-list", page, currentPageSize, debouncedSearchText],
    () =>
      usersService.getUsersList({
        page,
        pageSize: currentPageSize,
        query: debouncedSearchText,
      }),
    {
      refetchOnMount: true,
    },
  );

  const updateUserMutation: UseMutationResult<
    UserDto,
    unknown,
    UpdateUserDto,
    unknown
  > = useMutation(
    (data: UpdateUserDto): Promise<UserDto> => usersService.update(data),
    {
      onSuccess: () => {
        setSelectedUser(null);
        setIsOpenModal(false);
        usersQuery.refetch();
      },
    },
  );

  const resetUserDraftsMutation: UseMutationResult<
    unknown,
    unknown,
    ResetUserDraftsDto,
    unknown
  > = useMutation(
    (data: ResetUserDraftsDto): Promise<any> => usersService.reDealCards(data),
    {
      onSuccess: () => {
        setSelectedUser(null);
        setIsOpenAlertModal(null);
        usersQuery.refetch();
      },
    },
  );

  const resetUserAccountMutation: UseMutationResult<
    unknown,
    unknown,
    ResetUserAccountDto,
    unknown
  > = useMutation(
    (data: ResetUserAccountDto): Promise<any> =>
      usersService.resetUserAccount(data),
    {
      onSuccess: () => {
        setSelectedUser(null);
        setIsOpenAlertModal(null);
        usersQuery.refetch();
      },
    },
  );

  const handleCloseDialog = () => {
    setIsOpenModal(false);
    setSelectedUser(null);
  };

  const handleCloseAlertDialog = () => {
    resetUserDraftsMutation.reset();
    setIsOpenAlertModal(null);
  };

  const handleSaveDialog = (data: UserDto) => {
    if (selectedUser?.id) {
      updateUserMutation.mutate({ ...data, id: selectedUser.id });
    }
  };

  const handleProceedDialog = (data: UserDto) => {
    if (selectedUser?.id) {
      if (isOpenAlertModal === "reset-user") {
        resetUserAccountMutation.mutate({ ...data, id: selectedUser.id });
      } else if (isOpenAlertModal === "redeal-cards") {
        resetUserDraftsMutation.mutate({ ...data, id: selectedUser.id });
      }
    }
  };

  const containerRef = useRef<any>();

  const onOpenUser = (
    user: UserDto,
    type: "dailyDraftCounter" | "draftTokensRemaining",
  ) => {
    setSelectedUser(user);
    setModalType(type);
    setIsOpenModal(true);
  };

  const onTableChange = ({ current, pageSize }: TablePaginationConfig) => {
    setPage(current || 1);
    if (pageSize !== currentPageSize) {
      setPageSize(pageSize || 20);
      setPage(1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if ("offsetHeight" in containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight - 140);
      }
    }, 100);
  }, []);

  // Update debounced search text after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setPage(1);
      setDebouncedSearchText(searchText);
    }, 300); // Adjust debounce delay as needed

    // Clear the timeout if the effect re-runs (when searchText changes)
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const getErrorMessage = (error: any) => {
    if (error) {
      if (error instanceof HttpErrorResponse) {
        return error.errors.get("message" as any) as unknown as string;
      }
      return `Player doesnt have a full set of cards available right now`;
    }
  };

  return (
    <Layout className={styles.tableLayout}>
      {isOpenModal ? (
        <ChangeUserModal
          open={isOpenModal}
          user={selectedUser}
          onClose={handleCloseDialog}
          onSave={handleSaveDialog}
          isLoading={updateUserMutation.isLoading}
          isError={updateUserMutation.isError}
          type={modalType}
        />
      ) : (
        <></>
      )}

      {isOpenAlertModal ? (
        <AlertModal
          open={Boolean(isOpenAlertModal)}
          description={
            isOpenAlertModal === "redeal-cards"
              ? `Are you sure you want to re-deal the draft cards of ${selectedUser?.nickname} user?`
              : `Are you sure you want to reset the account of ${selectedUser?.nickname} user?`
          }
          onClose={handleCloseAlertDialog}
          isLoading={updateUserMutation.isLoading}
          error={getErrorMessage(resetUserDraftsMutation.error)}
          onProceed={handleProceedDialog}
        />
      ) : (
        <></>
      )}

      <Row
        style={{
          height: 32,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            rowGap: 5,
            columnGap: 5,
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={5}>
            Users ({usersQuery.data?.total})
          </Typography.Title>

          <Button
            type="primary"
            onClick={() => {
              // setSelectedUser(defaultUser);
              setIsOpenModal(true);
            }}
          >
            Add User
          </Button>
        </Col>
        <Col>
          <Form.Item className="search-input-container">
            <Input
              onChange={handleSearch}
              value={searchText}
              name="search"
              style={{
                minWidth: 200,
              }}
              placeholder="Search by Name , Phone number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Layout.Content
        ref={containerRef}
        className={styles.content}
        style={{ backgroundColor: "white" }}
      >
        <Table
          rowKey="key"
          dataSource={usersQuery.data?.data || []}
          onChange={onTableChange}
          columns={columns}
          loading={usersQuery.isFetching}
          pagination={{
            current: page,
            total: usersQuery.data?.total || 0,
            pageSize: currentPageSize,
            showSizeChanger: true,
          }}
          size="small"
          scroll={{ y: containerHeight }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default Users;
