export const ROUTES = {
  LOGIN_PAGE: "/login",
  // TWO_FA: '/two-factor-authentication',

  MAIN_PAGE: "/",
  PLAYERS: "/players",
  USERS: "/users",
  SETTINGS: "/settings",
  NOTIFICATIONS: "/notifications",
  CONFIGURATIONS: "/configurations",

  NOT_FOUND: "*",
  INTERNAL_SERVER: "/500",
  ACCESS_DENIED: "/403",
};
