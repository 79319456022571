import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

import styles from "./Sidebar.module.scss";
import {
  BellOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { ROUTES } from "@routes/routes";

const menu = [
  {
    key: "users",
    icon: <UsergroupAddOutlined />,
    label: <Link to={ROUTES.USERS}>Users</Link>,
    pathname: ROUTES.USERS,
  },
  {
    key: "players",
    icon: <UsergroupAddOutlined />,
    label: <Link to={ROUTES.PLAYERS}>Players</Link>,
    pathname: ROUTES.PLAYERS,
  },
  {
    key: "notifications",
    icon: <BellOutlined />,
    label: <Link to={ROUTES.NOTIFICATIONS}>Notifications</Link>,
    pathname: ROUTES.NOTIFICATIONS,
  },
  {
    key: "configurations",
    icon: <SettingOutlined />,
    label: <Link to={ROUTES.CONFIGURATIONS}>Configurations</Link>,
    pathname: ROUTES.CONFIGURATIONS,
  },
  {
    key: "settings",
    icon: <SettingOutlined />,
    label: <Link to={ROUTES.SETTINGS}>Settings</Link>,
    pathname: ROUTES.SETTINGS,
  },
];

const Sidebar = () => {
  return (
    <Layout.Sider width={270}>
      <Menu mode="inline" className={styles.menu} items={menu} />
    </Layout.Sider>
  );
};

export default Sidebar;
