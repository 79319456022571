import { Container } from "inversify";

import { DiEntity, DiEntityIdentifier } from "./types";
import { BaseService } from "@core/services/base";
import { HttpClientService } from "@core/services/http-client.service";
import { ConfigService } from "@core/services/config.service";
import { StorageService } from "@core/services/storage.service";
import { sharedAppServices } from "@shared/services";
import { DI_TOKENS } from "@shared/constants/di";
import { SettingsService } from "@shared/services/settings.service";

const diContainer = new Container();

const entitiesConfig: Array<{ diToken: DiEntityIdentifier; entity: DiEntity }> =
  [
    { diToken: DI_TOKENS.baseService, entity: BaseService },
    { diToken: DI_TOKENS.httpClientService, entity: HttpClientService },
    { diToken: DI_TOKENS.configService, entity: ConfigService },
    { diToken: DI_TOKENS.storageService, entity: StorageService },
    { diToken: DI_TOKENS.settingsService, entity: SettingsService },
    ...sharedAppServices.map((x) => ({ diToken: x.diToken, entity: x.entity })),
  ];

entitiesConfig.forEach(({ diToken, entity }) => {
  diContainer.bind(diToken).to(entity).inSingletonScope();
});

export { diContainer };
