import { Alert, Button, Form, Modal, Space } from "antd";

interface IModal {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  description: string;
  error?: string;
  onProceed: Function | ((args?: any) => Promise<any>);
}

const AlertModal = ({
  open,
  onClose,
  isLoading,
  error,
  onProceed,
  description,
}: IModal) => {
  const [form] = Form.useForm();

  const handleFinish = async () => {
    try {
      await onProceed();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal title={"Alert"} open={open} onCancel={onClose} footer={null}>
      <Form
        key={`alert`}
        form={form}
        requiredMark={false}
        onFinish={handleFinish}
      >
        {error && (
          <Form.Item>
            <Alert message="Error" description={error} type="error" showIcon />
          </Form.Item>
        )}

        <Form.Item>{description}</Form.Item>

        <Space
          style={{ justifyContent: "flex-end", width: "100%", marginTop: 10 }}
        >
          <Form.Item>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Yes
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AlertModal;
