import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from "class-validator";

@ValidatorConstraint({ name: "samePassword", async: false })
export class SamePassword implements ValidatorConstraintInterface {
  validate(text: string, { object }: ValidationArguments) {
    const { password } = object as { password: string };
    return !!text && text.length >= 1 && text === password;
  }

  defaultMessage() {
    return "Passwords need to match";
  }
}
