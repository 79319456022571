import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { ISettingsService } from "@shared/interfaces/settings-service.interface";
import { SettingsDto } from "@shared/types/settings/settings.dto";

@appInjectable()
export class SettingsService extends BaseService implements ISettingsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  async get(): Promise<SettingsDto> {
    const { data } = await this.httpClient.get<SettingsDto>("/admin/settings", {
      baseURL: this.baseURL,
    });

    return data;
  }

  async update(dto: SettingsDto): Promise<SettingsDto> {
    const { data } = await this.httpClient.patch<SettingsDto>(
      "/admin/settings",
      dto,
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }
}
