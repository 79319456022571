import { Routes, Route, Navigate } from "react-router-dom";

import { ROUTES } from "./routes";

import AuthorizationLayout from "@shared/layouts/AuthorizationLayout/AuthorizationLayout";
import MainLayout from "@shared/layouts/MainLayout/MainLayout";
import { AuthorizedUserGuard } from "@shared/components/guards/authorized-user-guard";
import { UnauthorizedUserGuard } from "@shared/components/guards/unauthorized-user-guard";
import Players from "../pages/Players/Players";
import Notifications from "../pages/Notifications/Notifications";
import Configurations from "../pages/Configurations/Configurations";
import NotFound from "../pages/NotFound/NotFound";
import AccessDenied from "../pages/AccessDenied/AccessDenied";
import InternalServer from "../pages/InternalServer/InternalServer";
import { Login } from "../pages/Login";
import Users from "pages/Users/Users";
import Settings from "pages/Settings/Settings";

const AppRouter = () => (
  <Routes>
    <Route element={<UnauthorizedUserGuard />}>
      <Route element={<AuthorizationLayout />}>
        <Route path={ROUTES.LOGIN_PAGE} index element={<Login />} />
        {/*<Route path={routes.TWO_FA} element={<TwoFactorAuthentication />} />*/}
      </Route>
    </Route>

    <Route element={<AuthorizedUserGuard />}>
      <Route path={ROUTES.MAIN_PAGE} element={<MainLayout />}>
        <Route index element={<Navigate to={ROUTES.USERS} replace />} />
        <Route path={ROUTES.PLAYERS} element={<Players />} />
        <Route path={ROUTES.USERS} element={<Users />} />
        <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
        <Route path={ROUTES.SETTINGS} element={<Settings />} />
        <Route path={ROUTES.CONFIGURATIONS} element={<Configurations />} />
      </Route>
    </Route>

    <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
    <Route path={ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
    <Route path={ROUTES.INTERNAL_SERVER} element={<InternalServer />} />
  </Routes>
);

export default AppRouter;
